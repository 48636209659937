// src/pages/UserIdPage.js
import React, { useState } from 'react';
import { Container, Button, Form } from 'react-bootstrap';
import ImportReceiptsModal from '../components/ImportReceiptsModal';
import getUniqueIdentifier from '../components/uniqueIdentifier'; // Import your unique identifier function
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import { FaCopy } from 'react-icons/fa'; // Import the copy icon from react-icons



const UserIdPage = () => {
  const [copied, setCopied] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const userId = getUniqueIdentifier(); // Get the unique identifier

  const navigate = useNavigate(); // Use useNavigate for navigation


  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(userId).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000); // Reset the copied state after 2 seconds
    });
  };

  return (
  
    <Container className="mt-5">
      <h2 className="text-center ">User Profile</h2>
      
      <div className="d-flex justify-content-center">
        <Form.Control
          type="text"
          readOnly
          value={userId}
          className="text-center mt-3"
          style={{ maxWidth: '400px', margin: '0 auto' }}
        />
       
       <button className="btn btn-light btn-copy mt-3" onClick={handleCopy}>  
       <FaCopy />
       </button>
       </div>
       {copied && <p className="text-success mt-2">Copied to clipboard!</p>}
        
     
      <div className="d-flex justify-content-center">
      <div className="close-button">
        <Button variant="primary" className="mt-5 mr-3" onClick={handleShowModal}>
          Import Receipts
        </Button>
    </div>
      <ImportReceiptsModal show={showModal} handleClose={handleCloseModal} currentUserId={userId} />
     <div className="back-button"><button className="btn btn-secondary mt-5" onClick={() => navigate(-1)}>Back</button></div> 
      
      </div>
    </Container>
  );
};

export default UserIdPage;
