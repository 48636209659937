// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {getFirestore} from "firebase/firestore"

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBI_FXf-G5Ssux5AIBHmLOiZhJK38P5nOM",
  authDomain: "qrbill-3e712.firebaseapp.com",
  databaseURL: "https://qrbill-3e712-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "qrbill-3e712",
  storageBucket: "qrbill-3e712.appspot.com",
  messagingSenderId: "639399731239",
  appId: "1:639399731239:web:b1b7ee23195107285cae02",
  measurementId: "G-Q5NMTJFS6T"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const db = getFirestore(app);