import React, { useEffect, useState } from 'react';
import { db } from '../config/firebase';
import { doc, getDoc } from "firebase/firestore";
import { useParams, useNavigate } from 'react-router-dom';
import getCurrencySymbol from '../components/currencyHelper';
import { format } from 'date-fns';
import '../components/TransactionDetail.css'; // Custom CSS

const TransactionDetail = () => {
  const { id } = useParams();
  const [transaction, setTransaction] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTransaction = async () => {
      try {
        const docRef = doc(db, 'transactions', id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setTransaction(docSnap.data());
        } else {
          setError('Transaction not found');
        }
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchTransaction();
  }, [id]);

  const goBack = () => {
    navigate(-1); // Navigate back to the previous page
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const items = transaction.transaction_data.products || [];
  const currencySymbol = getCurrencySymbol(transaction.transaction_data.currency);
  const formattedDate = format(new Date(transaction.transaction_data.timestamp), 'MMMM d, yyyy');
  const formattedTime = format(new Date(transaction.transaction_data.timestamp), 'HH:mm'); // Adding time format

  // Parse total amount as a float
  const totalAmount = parseFloat(transaction.transaction_data.amount) || 0;

  return (
    <div className="transaction-detail-container">
        <h2 className="receipt-header">My receipt overview</h2>
      <div className="receipt-overview-card">
        <div className="merchant-info">
          <h4>{transaction.merchant_data.merchant_profile.business_name}</h4>
          <div className="d-flex justify-content-around my-3">
            <span className="badge badge-primary">{transaction.merchant_data.merchant_profile.address.city}</span>
            <span className="badge badge-secondary">{formattedDate}</span>
            <span className="badge badge-secondary time-tag">{formattedTime}</span> {/* Time tag */}
          </div>
        </div>

        <div className="transaction-items">
          {items.map((item, index) => {
            const price = parseFloat(item.price) || 0; // Parse the price as a float, defaulting to 0 if invalid
            return (
              <div key={index} className="item-row">
                <span>{item.name}</span>
                <span>{currencySymbol}{price.toFixed(2)}</span> {/* Two decimal places */}
              </div>
            );
          })}
        </div>

        <div className="transaction-summary">
          <div className="summary-row">
            <span>SUBTOTAL</span>
            <span>{currencySymbol}{(parseFloat(transaction.transaction_data.subtotal) || 0).toFixed(2)}</span> {/* Two decimal places */}
          </div>
          <div className="summary-row">
            <span>TAX</span>
            <span>{currencySymbol}{(parseFloat(transaction.transaction_data.tax_amount) || 0).toFixed(2)}</span> {/* Two decimal places */}
          </div>
          <div className="summary-row total">
            <span>TOTAL</span>
            <span>{currencySymbol}{totalAmount.toFixed(2)}</span> {/* Two decimal places */}
          </div>
        </div>
      </div>

      <div className="close-button">
        <button onClick={goBack}>BACK</button>
      </div>
    </div>
  );
};

export default TransactionDetail;
