import React, { useEffect, useState } from 'react';
import { getLastTransaction, getReceiptDetails } from '../components/sumupService';
import getCurrencySymbol from '../components/currencyHelper';
import { format } from 'date-fns';
import { db } from "../config/firebase";
import { collection, addDoc } from "firebase/firestore";
import getUniqueIdentifier from '../components/uniqueIdentifier';
import { useNavigate } from 'react-router-dom';
import '../components/TransactionDetail.css'; // Reuse the same CSS from TransactionDetail

const LastTransactionDetails = () => {

 
  const [receipt, setReceipt] = useState(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchReceiptData = async () => {
      try {
        const lastTransaction = await getLastTransaction();
        const receiptDetails = await getReceiptDetails(lastTransaction.id);
        setReceipt(receiptDetails);
      } catch (err) {
        setError(err.message);
      }
    };

    fetchReceiptData();
  }, []);

  const saveToFirebase = async () => {
    const userId = getUniqueIdentifier();

    try {
      await addDoc(collection(db, 'transactions'), {
        ...receipt,
        userId
      });
      alert('Transaction saved successfully!');
    } catch (error) {
      console.error('Error saving transaction:', error);
      alert('Error saving transaction.');
    }
  };

  const navigateToReceipts = () => {
    navigate('/');
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!receipt) {
    return <div>Loading...</div>;
  }

  const items = receipt.transaction_data.products || [];
  const currencySymbol = getCurrencySymbol(receipt.transaction_data.currency);

  const formattedDate = format(new Date(receipt.transaction_data.timestamp), 'MMMM d, yyyy');
  const formattedTime = format(new Date(receipt.transaction_data.timestamp), 'HH:mm');
  console.log(receipt.transaction_data);
  return (
    <div className="transaction-detail-container">
      <h2 className="receipt-header">Last receipt</h2>
      <div className="receipt-overview-card">
        <div className="merchant-info">
          <h4>{receipt.merchant_data.merchant_profile.business_name}</h4>
          <div className="d-flex justify-content-around my-3">
            <span className="badge badge-primary">{receipt.merchant_data.merchant_profile.address.city}</span>
            <span className="badge badge-secondary">{formattedDate}</span>
            <span className="badge badge-secondary time-tag">{formattedTime}</span> {/* Time tag */}
          </div>
        </div>

        <div className="transaction-items">
          {items.map((item, index) => {
            const price = parseFloat(item.price) || 0;
            return (
              <div key={index} className="item-row">
                <span>{item.name}</span>
                <span>{currencySymbol}{price.toFixed(2)}</span> {/* Two decimal places */}
              </div>
            );
          })}
        </div>

        <div className="transaction-summary">
          <div className="summary-row">
            <span>SUBTOTAL</span>
            <span>{currencySymbol}{(parseFloat(receipt.transaction_data.subtotal) || 0).toFixed(2)}</span> {/* Two decimal places */}
          </div>
          <div className="summary-row">
            <span>TAX</span>
            <span>{currencySymbol}{(parseFloat(receipt.transaction_data.tax_amount) || 0).toFixed(2)}</span> {/* Two decimal places */}
          </div>
          <div className="summary-row total">
            <span>TOTAL</span>
            <span>{currencySymbol}{(parseFloat(receipt.transaction_data.amount) || 0).toFixed(2)}</span> {/* Two decimal places */}
          </div>
        </div>

        <div className="text-center">
      
          <div className="close-button">
        <button onClick={saveToFirebase}>Save to Account</button>
      </div>
        </div>


      </div>
    </div>
  );
};

export default LastTransactionDetails;
