// src/components/ImportReceiptsModal.js
import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { db } from '../config/firebase'; // Ensure Firestore is imported correctly
import { collection, query, where, getDocs, updateDoc, doc, writeBatch } from "firebase/firestore"; // Import Firestore methods

const ImportReceiptsModal = ({ show, handleClose, currentUserId }) => {
  const [newUserId, setNewUserId] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const handleImport = async () => {
    setError(null);
    setSuccess(null);

    try {
      const q = query(collection(db, 'transactions'), where("userId", "==", newUserId));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        setError('No receipts found for the provided user ID.');
        return;
      }

      const batch = writeBatch(db);
      querySnapshot.forEach((document) => {
        const docRef = doc(db, 'transactions', document.id);
        batch.update(docRef, { userId: currentUserId });
      });

      await batch.commit();
      setSuccess('Receipts imported successfully!');
      handleClose();
    } catch (err) {
      setError('Error importing receipts: ' + err.message);
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Import Receipts</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group>
            <Form.Label>New User ID</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter new user ID"
              value={newUserId}
              onChange={(e) => setNewUserId(e.target.value)}
            />
          </Form.Group>
        </Form>
        {error && <p className="text-danger mt-2">{error}</p>}
        {success && <p className="text-success mt-2">{success}</p>}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleImport}>
          Import
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ImportReceiptsModal;
