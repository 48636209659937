const API_BASE_URL = 'https://api.sumup.com/v0.1/me/transactions/history';
const RECEIPT_BASE_URL = 'https://api.sumup.com/v1.1/receipts';

const apiKey = 'sup_sk_6FWdYUxeBv9YgWXIIsGPBxNpgd3YgWY1u'; // Hard-coded API key
const merchantId = 'MCER3U4M'; // Replace with your actual merchant ID

const getTransactions = async () => {
  try {
    console.log('Using API Key:', apiKey); // Debug API key
    const response = await fetch(`${API_BASE_URL}?limit=10&order=desc`, {
      headers: {
        'Authorization': `Bearer ${apiKey}`,
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      console.log('Response Status:', response.status);
      throw new Error('Network response was not ok ' + response.statusText);
    }

    const data = await response.json();
    console.log('Fetched Transactions:', data.items); // Log fetched transactions

    if (!Array.isArray(data.items) || data.items.length === 0) {
      throw new Error('No transactions found.');
    }

    // Sort transactions by timestamp in descending order
    data.items.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
    return data.items; // Ensure it returns an array
  } catch (error) {
    console.error('Error fetching transactions:', error);
    throw error;
  }
};

const getLastTransaction = async () => {
  try {
    const transactions = await getTransactions();
    return transactions[0]; // Return the first item which should be the latest transaction
  } catch (error) {
    console.error('Error fetching the latest transaction:', error);
    throw error;
  }
};

const getReceiptDetails = async (transactionId) => {
  try {
    console.log('Fetching receipt details for transaction ID:', transactionId); // Debug
    const response = await fetch(`${RECEIPT_BASE_URL}/${transactionId}?mid=${merchantId}`, {
      headers: {
        'Authorization': `Bearer ${apiKey}`,
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      console.log('Response Status:', response.status);
      const errorText = await response.text(); // Get the response text
      console.log('Response Text:', errorText); // Log the response text
      throw new Error('Network response was not ok ' + response.statusText);
    }

    const data = await response.json();
    return data; // Return the receipt details
  } catch (error) {
    console.error('Error fetching receipt details:', error);
    throw error;
  }
};

export { getTransactions, getLastTransaction, getReceiptDetails };
