import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation

const InformationHowTo = () => {
  const navigate = useNavigate(); // Use useNavigate for navigation

  return (
    
    <div className="transaction-detail-container">
      
      <h2 className="header-text">How to Use Receipty</h2>
   
      {/* Left-aligned instruction list */}
      <ol className="instruction-list mt-4 text-left">
        <li>Scan the tag</li>
        <li>Click save</li>
        <li>View the receipt on your browser</li>
      </ol>
      
      <div className="important-note mt-5">
        <h4>Why receipty?</h4>
        <p>No card numbers, emails, or phone numbers saved. Privacy is our number one priority.</p>
      </div>

      <div className="back-button text-center mt-5">
        <button className="btn btn-secondary" onClick={() => navigate(-1)}>Back</button>
      </div>
    </div>
  );
};

export default InformationHowTo;
