import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FaHome, FaUser, FaInfoCircle } from 'react-icons/fa';
import './Footer.css';

const Footer = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className="footer">
      <button
        className={`footer-button ${location.pathname === '/' ? 'active' : ''}`}
        onClick={() => navigate('/')}
      >
        <FaHome size={24} />
      </button>
      <button
        className={`footer-button ${location.pathname === '/user-id' ? 'active' : ''}`}
        onClick={() => navigate('/user-id')}
      >
        <FaUser size={24} />
      </button>
      <button
        className={`footer-button ${location.pathname === '/howto' ? 'active' : ''}`}
        onClick={() => navigate('/howto')}
      >
        <FaInfoCircle size={24} />
      </button>
    </div>
  );
};

export default Footer;