import React from 'react';

const Header2 = () => {
  return (
    <header className="header-container">
      <img src="/Group 29.svg" alt="Receipty Logo" className="header-logo" />
    </header>
  );
};

export default Header2;