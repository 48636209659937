import React, { useEffect, useState } from 'react';
import { db } from '../config/firebase'; // Ensure Firestore is imported correctly
import { collection, query, where, getDocs } from "firebase/firestore"; // Import Firestore methods
import getUniqueIdentifier from '../components/uniqueIdentifier'; // Import the unique identifier function
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import getCurrencySymbol from '../components/currencyHelper'; // Import the currency helper function
import { format } from 'date-fns'; // Import date formatting function

const ReceiptOverview = () => {
  const [receipts, setReceipts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate(); // Use useNavigate for navigation

  useEffect(() => {
    const fetchReceipts = async () => {
      const userId = getUniqueIdentifier(); // Get the unique identifier

      try {
        const q = query(collection(db, 'transactions'), where("userId", "==", userId));
        const querySnapshot = await getDocs(q);
        const receiptsList = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setReceipts(receiptsList);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchReceipts();
  }, []);

  const viewTransactionDetail = (id) => {
    navigate(`/transaction/${id}`);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="receipt-overview-container">
      <h2 className="receipt-header">My receipts</h2>
      {receipts.length > 0 ? (
        <div className="receipt-list">
          {receipts.map((receipt) => {
            const currencySymbol = getCurrencySymbol(receipt.transaction_data.currency);
            const formattedDate = format(new Date(receipt.transaction_data.timestamp), 'd MMMM, yyyy');

            // Ensure amount is a number and provide a fallback if not
            const amount = parseFloat(receipt.transaction_data.amount);
            const displayAmount = isNaN(amount) ? 'N/A' : amount.toFixed(2);

            return (
              <div
                key={receipt.id}
                className="receipt-card"
                onClick={() => viewTransactionDetail(receipt.id)}
                style={{ cursor: 'pointer' }}
              >
                <div>
                  <div className="receipt-name">{receipt.merchant_data.merchant_profile.business_name}</div>
                  <div className="receipt-date">{formattedDate}</div>
                </div>
                <div className="receipt-amount">
                  {currencySymbol}{displayAmount}
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <p className="text-center">No receipts found.</p>
      )}
    </div>
  );
};

export default ReceiptOverview;
