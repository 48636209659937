const getCurrencySymbol = (currencyCode) => {
    const currencySymbols = {
      USD: '$',
      EUR: '€',
      GBP: '£',
      // Add more currency codes and symbols as needed
    };
  
    return currencySymbols[currencyCode] || currencyCode;
  };
  
  export default getCurrencySymbol;
  