import { useEffect, useState } from "react";
import logo from './logo.svg';
import './App.css';
import { db } from "./config/firebase";
import Badge from 'react-bootstrap/Badge';
import Stack from 'react-bootstrap/Stack';
import ListGroup from 'react-bootstrap/ListGroup';
import { collection, getDocs, query, where } from "firebase/firestore"; // Assuming you're using Firestore
import LastTransactionDetails from "./pages/LastTransactionDetails";
import ReceiptOverview from "./pages/ReceiptOverview";
import UserIdPage from './pages/UserIdPage'; // Import the new component
import InformationHowTo from "./pages/InformationHowTo";
import TransactionDetail from './pages/TransactionDetail'; // Import the new component
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header2'; // Import the Header component
import 'bootstrap/dist/css/bootstrap.min.css';
import Footer from './components/Footer'; // Import the new Footer component



function App() {
  
  const merchantId = 'MCER3U4M';
  const merchantId2 = 'MCGNCQST';


  return (
    <Router>
      <div className="App">
      <header className="my-4 text-center">
         
        </header>
        <Header /> {}

        
       
        <Routes>
        <Route path="/" element={<div><ReceiptOverview /></div>} />
          <Route path="/culas" element={<LastTransactionDetails/>} />
          <Route path="/transaction/:id" element={<TransactionDetail />} /> {/* New route */}
          <Route path="/user-id" element={<UserIdPage />} />
          <Route path="/howto" element={<InformationHowTo />} />
        </Routes>
        <Footer /> {/* Include the Footer component */}
      </div>
    </Router>
  );

 
}

export default App;